import React, { useState } from "react"
import { Link } from "gatsby"

// Components
import { FacebookIcon, GoogleIcon, TwitterIcon } from "../icons"

// Stylesheet
import styles from "./index.module.scss"

// Images
import LogoBlack from "../../images/logo-black.svg"
import LogoWhite from "../../images/logo-white.svg"

// Mobile toggle view
const MobileToggleNav = ({ toggle }) => {
  return (
    <div className={`${styles.mobileToggleNav} px-4 items-start`}>
      <div className={styles.section}>
        <button className={styles.navToggle} onClick={() => toggle(false)}>
          Close Menu
        </button>
      </div>
      <div className={styles.section}>
        <NavLinks mobile toggle={toggle} />
      </div>
      <div className={styles.section}>
        <SocialContainer />
      </div>
      <div className={styles.section}>
        <LogoContainer />
      </div>
    </div>
  )
}

// Social container
const SocialContainer = () => (
  <div className={`${styles.socialContainer} flex justify-center items-center`}>
    <a className={styles.icon}>
      <FacebookIcon />
    </a>
    <a className={styles.icon}>
      <GoogleIcon />
    </a>
    <a className={styles.icon}>
      <TwitterIcon />
    </a>
  </div>
)

// Logo container
const LogoContainer = () => (
  <Link to="/">
    <img
      src={LogoBlack}
      alt="Tribute2Mom Logo"
      className={`${styles.blackLogo} md:w-auto`}
    />
    <img
      src={LogoWhite}
      alt="Tribute2Mom Logo"
      className={`${styles.whiteLogo} w-40 md:w-auto`}
    />
  </Link>
)

// Nav Links
const NavLinks = ({ toggle, mobile }) => {
  const webLinks = [
    { name: "Home", route: "/" },
    { name: "Tributes", route: "/tributes" },
    { name: "Articles", route: "/articles" },
    { name: "Create a Tribute", route: "/create-tribute" },
  ]
  const mobileLinks = [
    { name: "Home", route: "/" },
    { name: "Tributes", route: "/tributes" },
    { name: "Articles", route: "/articles" },
    { name: "Create a Tribute", route: "/create-tribute" },
  ]

  const links = mobile ? mobileLinks : webLinks

  const activeNavStyles = { color: "#c09a52" }
  return (
    <nav>
      <div className={`${mobile ? "hidden" : ""} lg:hidden`}>
        <button className={styles.navToggle} onClick={() => toggle(true)}>
          Menu
        </button>
      </div>
      <div
        className={`${
          mobile ? "flex flex-col items-center" : "hidden"
        } text-white lg:block`}
      >
        {links.map((link, index) => (
          <Link
            to={link.route}
            key={index}
            className={styles.navLink}
            activeStyle={links.length !== index + 1 ? activeNavStyles : null}
          >
            {link.name}
          </Link>
        ))}
      </div>
    </nav>
  )
}

const Header = ({ transparentHeader }) => {
  const [navToggle, setNavToggle] = useState(false)
  return (
    <header
      className={`${styles.header} ${
        transparentHeader ? styles.transparentBg : ""
      }`}
    >
      {navToggle ? (
        <MobileToggleNav toggle={setNavToggle} />
      ) : (
        <div className="max-w-6xl px-4 xl:px-0 mx-auto flex justify-between items-center">
          <LogoContainer />
          <NavLinks toggle={setNavToggle} />
        </div>
      )}
    </header>
  )
}

export default Header

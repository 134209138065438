/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, withPrefix } from "gatsby"

function SEO({ description, lang, meta, title, image }) {
  const { site } = useStaticQuery(graphql`
    query WebsiteQuery {
      site: contentfulWebsiteDetails {
        ogtitle
        ogdescription
        ogimage {
          fluid {
            src
          }
        }
        twitterimage {
          fluid {
            src
          }
        }
      }
    }
  `)

  const { ogtitle, ogdescription, ogimage, twitterimage } = site

  const metaDescription = description || ogdescription
  const defaultTitle = title || ogtitle
  const img = image || ogimage

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: img.fluid.src,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "Cosm",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: twitterimage.fluid.src,
        },
      ].concat(meta)}
    >
      <script
        type="text/javascript"
        src="https://www.bugherd.com/sidebarv2.js?apikey=ntnsn5a7hauqqbmylumyhq"
        async="true"
      ></script>
      <script src={withPrefix("clarity.js")} type="text/javascript"></script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO

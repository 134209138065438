import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import addToMailchimp from "gatsby-plugin-mailchimp"

// Components
import {
  FacebookIcon,
  GoogleIcon,
  TwitterIcon,
  ReCycle,
  SubscribeSuccessIcon,
  SubscribeErrorIcon,
} from "../icons"

// Stylesheet
import styles from "./index.module.scss"

// Images
import strokeImg from "../../images/stroke3.svg"
import Logo from "../../images/logo-white.svg"

const SocialContainer = ({
  data: { facebookLink, googleLink, twitterLink },
}) => (
  <div
    className={`${styles.socialContainer} flex w-full justify-center md:justify-end`}
  >
    <a
      href={facebookLink}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.icon}
    >
      <FacebookIcon />
    </a>
    {/* <a
      href={facebookLink}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.icon}
    >
      <GoogleIcon />
    </a>
    <a
      href={facebookLink}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.icon}
    >
      <TwitterIcon />
    </a> */}
  </div>
)

const Footer = ({ stroke }) => {
  const [isLoading, setLoading] = useState(false)
  const [status, setStatus] = useState(null)

  const { footer } = useStaticQuery(graphql`
    query FooterQuery {
      footer: contentfulWebsiteDetails {
        newsletterTitle
        facebookLink
        googleLink
        twitterLink
        address
        contactNumber
        customerSupportWeekdayTiming
        customerSupportWeekendTiming
      }
    }
  `)

  const {
    newsletterTitle,
    facebookLink,
    googleLink,
    twitterLink,
    address,
    contactNumber,
    customerSupportWeekdayTiming,
    customerSupportWeekendTiming,
  } = footer
  const socialLinks = { facebookLink, googleLink, twitterLink }

  const EmailSubmissionSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  })

  const formSubmit = async values => {
    if (values) {
      setLoading(true)
      await addToMailchimp(values.email).then(data => {
        setStatus(data.result)
      })
    }
  }
  const copyrightDate = new Date()
  return (
    <div className={styles.footer}>
      {stroke && <img src={strokeImg} className={styles.stroke} />}
      <div className="px-4 pt-8 md:pt-16 xl:px-0">
        <div className="max-w-5xl mx-auto flex flex-col md:flex-row items-center">
          <h3 className="text-center md:text-left w-full md:w-1/2">
            {newsletterTitle}
          </h3>
          <div className="w-full md:w-1/2 m-0">
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={EmailSubmissionSchema}
              onSubmit={formSubmit}
            >
              {({ errors, touched, validateForm }) => (
                <Form className="md:flex items-center">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Enter your email address here…"
                    error={errors.email && touched.email ? "true" : "false"}
                    disabled={isLoading}
                    required
                    className={`${styles.emailInput} ${
                      status === "success"
                        ? styles.success
                        : status === "error"
                        ? styles.error
                        : ""
                    }`}
                  />

                  <button
                    type="submit"
                    disabled={isLoading}
                    className={`flex justify-center items-center ${
                      styles.submitBtn
                    } ${
                      status === "success"
                        ? styles.success
                        : status === "error"
                        ? styles.error
                        : ""
                    }`}
                    onClick={() => validateForm().then(() => formSubmit())}
                  >
                    {status === "success" ? (
                      <>
                        Subscribed! <SubscribeSuccessIcon className="ml-2" />{" "}
                      </>
                    ) : status === "error" ? (
                      <>
                        Ooops, Error! <SubscribeErrorIcon className="ml-2" />{" "}
                      </>
                    ) : (
                      "Subscribe Me!"
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* Line seperator */}
        <hr className="max-w-5xl" />
        <div
          className={`${styles.contactDetails} max-w-5xl mx-auto flex flex-col md:flex-row gap-x-12 gap-y-8`}
        >
          <div className="w-full md:w-1/2 items-center md:items-start flex flex-col gap-6">
            <span className="semi">Address</span>
            <span className="regular w-full md:w-3/5 text-center md:text-left">
              {address}
            </span>
            <div className="flex items-center gap-4">
              <div className="h-12 w-12 bg-white flex items-center justify-center rounded-full">
                <ReCycle color="#2C2E36" />
              </div>
              <h3>
                <a href={`tel:${contactNumber}`}>{contactNumber}</a>
              </h3>
            </div>
          </div>
          <div className="md:w-1/2 items-center md:items-start flex flex-col gap-6">
            <span className="semi text-center md:text-left">
              Customer Service Hour
            </span>
            <div>
              <p className="text-center md:text-left">
                <span className={`${styles.semiFont} ${styles.bold}`}>
                  {customerSupportWeekdayTiming}
                </span>{" "}
                <span className={styles.semiFont}>Monday – Friday (PST)</span>
              </p>
              <p className="text-center md:text-left">
                <span className={`${styles.semiFont} ${styles.bold}`}>
                  {customerSupportWeekendTiming}
                </span>{" "}
                <span className={styles.semiFont}>Saturday & Sunday (PST)</span>
              </p>
            </div>
            <div className="flex gap-4">
              <span className={`${styles.link} semi`}>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </span>
              {/* <span className={`${styles.link} semi`}>
                <Link to="/">Terms of Use</Link>
              </span> */}
            </div>
          </div>
        </div>
        <hr className="max-w-5xl" />
        {/* Line seperator */}
        <div className="max-w-5xl mx-auto flex gap-4 flex-wrap md:flex-nowrap flex-row md:justify-between md:items-center">
          <div className="w-full md:w-1/5 md:m-0">
            <Link to="/">
              <img
                src={Logo}
                alt="Tribute2Mom logo"
                className="w-1/2 md:w-full mx-auto"
              />
            </Link>
          </div>
          <div className="w-full md:w-3/5 flex flex-col md:flex-row justify-center md:items-center ">
            <Link to="/tributes" className={styles.navLink}>
              Tributes
            </Link>
            <Link to="/articles" className={styles.navLink}>
              Articles
            </Link>
            <Link to="/create-tribute" className={styles.navLink}>
              Create a Tribute
            </Link>
          </div>
          <div className="flex-1 flex gap-4">
            <SocialContainer data={socialLinks} />
          </div>
        </div>
        {/* Line seperator */}
        {/* Line seperator */}
      </div>
      <div
        className={`${styles.copyrightSection} text-center py-4 mt-4 md:mt-16`}
      >
        <span className="md:mr-3 lg:mr-8 text-center">
          © {copyrightDate.getFullYear()} Dee Enterprises. All rights reserved.
        </span>
      </div>
    </div>
  )
}

export default Footer

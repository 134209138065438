/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import "../styles/styles.scss"
import "swiper/swiper.scss"
import "sweetalert2/src/sweetalert2.scss"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', { speed: 1000 })
}

const Layout = ({ children, transparentHeader = false, stroke = true }) => (
  <div className="relative">
    <Header transparentHeader={transparentHeader} />
    <main>{children}</main>
    <Footer stroke={stroke} />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
